@imgFolder : "/assets/front/img/";
@fontXs: 13px;
@fontSm: 15px;
@fontMd: 20px;
@fontLg: 30px;

@fontNormal: 400;
@fontLight: 300;
@fontBold: 700;

@softGrey: #e5e4df;
@bannerGrey: #f7f7f5;
@red: #F00;
@green: #42c700;

@fontGreen: green;
@fontRed:  red;


/* mariona email: */
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 0.8em;
  color: inherit;
}

body {
  font-family: 'proxima-nova', sans-serif;
  background-color: white;
  color: black;
  /* mariona email: */
  font-size: 15px;
  line-height: 1.42857;

}

img{
  max-width: 100%;
}

header {
  margin-bottom: 25px;
}

a{
  color: #000;
  text-decoration: underline;
}

.menu a, .utils-menu a{
  text-decoration: none;
}


a:hover{
  color: @red;
}

#forgot-password{
  display: none;
}

.fontBold {
  font-weight: @fontBold;
}

.fontNormal {
  font-weight: @fontNormal;
}

.fontLight {
  font-weight: @fontLight;
}

.fontLg {
  font-size: @fontLg;
}

.fontMd {
  font-size: @fontMd;
}

.fontSm {
  font-size: @fontSm;
}

.fontXs {
  font-size: @fontXs;
}

.fontGreen {
  color: @fontGreen;
}

.fontRed {
  color: @fontRed;
}

.bg-gradient-grey {
  background: @bannerGrey;
  //background: -moz-linear-gradient(top,  #ffffff 0%, #e5e4df 100%);
  //background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#e5e4df));
  //background: -webkit-linear-gradient(top,  #ffffff 0%,#e5e4df 100%);
  //background: -o-linear-gradient(top,  #ffffff 0%,#e5e4df 100%);
  //background: -ms-linear-gradient(top,  #ffffff 0%,#e5e4df 100%);
  //background: linear-gradient(to bottom,  #ffffff 0%,#e5e4df 100%);
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e4df',GradientType=0 );
  min-height: 300px;
}

.form-login .row, .form-register .row{
  margin: 2em 0
}

.form-fields .row {
  margin: 0.5em 0;
  [class*="col-"] {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

.utils-menu {
  margin: 10px 0 0 0;
}

.numItems{
  display: inline-block;
  margin-left: 5px;
  font-size: 16px;
  color: @red;
  font-weight: bold;
}

.padding .row {
  margin: 3em 0;
}

.row.detail {
  margin-bottom: 2em;
}

.menu {
  margin-top: 42px;
}

.menu li, .utils-menu li {
  display: inline;
  font-weight: @fontNormal;
  text-transform: uppercase;
}

.hmenu li {
  font-weight: 400;
  text-transform: uppercase;
}


.form-control{
  background-color:  @softGrey;
  color:#000;
}

*::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #000 ;
}
*:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #000;
  opacity: 1;
}
input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #000;
  opacity: 1;
}
*:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #000;
}

.menu li {
  font-size: @fontSm;
  text-transform: uppercase;
  margin-left: 30px;
}

.utils-menu li {
  font-size: @fontXs;
  margin-left: 15px;
}

a.logo {
  display: block;
  width: 220px;
  height: 76px;
  background: transparent url("@{imgFolder}logo.png") no-repeat;
}

div.logoPetit {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  height: 150px;
  background: transparent url("@{imgFolder}imgo.jpeg") no-repeat;
}


.box-gallery-product img {
  display: block;
}

.box-gallery-product h4 {

}
.btn-green {
  background-color: @green;
  color: white;
  text-decoration: none
}


.btn-black {
  background-color: black;
  color: white;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  padding: 4px 10px;
}

.btn-black:hover {
  color: white;
}

.box-gallery-product {
  margin-top: 40px;
}

.box-gallery-product h4 {
  font-weight: @fontBold;
  font-size: @fontMd;
  margin-top: 0
}

.box-gallery-product p {
  font-weight: @fontLight;
  font-size: @fontMd;
}

.box-gallery-product img {
  max-width: 100%;
  margin: 0 auto 20px auto
}

.separator {
  border-top: 1px solid black;
  margin: 50px 0;
  line-height: 0;
}

.article {
  margin-bottom: 30px;
}

.article img {
  width: 100%;
}

.article .inside {
  padding: 30px;
}

.article h2 {
  font-weight: @fontBold;
  font-size: @fontLg;
}

.article h2 a {
  color: black;
  text-decoration: none;
}

.article p {
  font-weight: @fontLight;
  font-size: @fontMd;
}

footer {
  background-color: @softGrey;
  padding: 40px 0;
  margin-top: 40px;
}

.slider {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
}
.row.page-margin{
  margin-top:3em;
}

ul.slider.height400 {
  height: 400px;
}
ul.slider.height470 {
  height: 470px;
}

.slider.detail-product{
  height: 450px;
}

.slider.home {
  height: 450px;
}

.page-margin h3{
  margin-top:0;
}

.slider li {
  -webkit-backface-visibility: hidden;
  position: absolute;
  display: none;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  list-style: none;
  background-size:cover;
  /*background-size:contain;*/
  background-repeat: no-repeat;
  min-height: 200px; /*afegit ricard per evitar desaparicio imatges en petit*/
}

.slider li:first-child {
  position: relative;
  display: block;
  float: left;
}

.slider img {
  display: block;
  max-height: 100%;
  max-width: 100%;
  border: 0;
  margin: auto;
}
.row.margin{
  margin-top: 3em
}

a.rslides_nav {
  display: block;
  position: absolute;
  //margin-top:-150px;
  margin-top: -230px;
  z-index: 1000;
}

a.rslides_nav.prev {
  position: absolute;
  display: block;
  background: transparent url("@{imgFolder}prev.png") no-repeat;
  width: 32px;
  height: 59px;
  left:10px;
}

a.rslides_nav.next {
  position:absolute;
  display: block;
  background: transparent url("@{imgFolder}next.png") no-repeat;
  width: 32px;
  height: 59px;
  right:10px;
}
.slider div.text-block {
  max-width: 80%;
  text-align: center;
  margin: auto;
  padding-top: 70px;
}

.slider span.title {
  font-size: @fontLg;
  font-weight: @fontBold;
  display: block;
  color: black;
  line-height: 1.2em;
}

.slider span.description {
  font-size: 26px;
  line-height: 1.2em;
  font-weight: 300;
  display: block;
  margin: 0;
  color: black;
}


.slider_caption{
  position: absolute;
  top: 20%;
  left: 13%;
  color: #FFF;
  padding: 30px 40px;
  /* background: rgba(255, 255, 255, 0.60); */
  max-width: 500px;
  text-align: center;
}

.copyright {
  margin: 30px 0;
}

footer ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

footer ul li {
  margin-bottom: 10px;
}

footer input[type="text"] {
  padding: 6px;
  line-height: 1.3;
  font-size: 14px;
  min-width: 260px;
}




.marcat {
  background: @red;
}


[id^=boto_]:hover, [id^=boto_]:focus{
  background: @red;
}




[id^=botod_] {
  width:20px;
  height:20px;
  border: black solid 1px;
  border-radius: 5px;
}

.required:after{
  content:'*';
  color:@red;
  padding-left:5px;
}


.fons {
  background-color: #F7F7F5;
  padding: 12px;
}

div.panell {
  width: 300px;
  height: 150px;
  background-color: #fff;
  padding: 4px 4px 4px 7px;
  border: 1px solid @red;
  border-left-width: 5px;
  font-size: 12px;
  border-radius: 4px;
  margin: 4px 4px 4px 4px;
}

/* ESTILS PER FACTURA */


.dreta {
  text-align: right;
}


.mesmenys {
  font-size: large;
  font-weight: bolder;
  line-height: 0.5em;
}
.codiDte {
  display: inline;
  width: 200px;
}

.eur:after {
  content:'€';
  padding-left:2px;
}
.tpc:after {
  content:'%';
  padding-left:2px;
}

.neg:before {
  content:'-';
  padding-right:2px;
}

.oferta {
  color: @red;
  font-weight: bolder;
}

.noestoc {
  color: @red;
  font-weight: bolder;
}
.total {
  font-weight: bolder;
  text-transform: uppercase;
}

.promo {
  color: green;
  font-weight: bolder;
}

.address .pais{
  font-weight: bolder;
}

div.modal{
  position: fixed;
  width: 600px;
  max-width: 100%;
  height: 150px;
  max-height: 100%;
  background-color: #FFF;
  z-index: 9000;
  left: 50%;
  top: 40%;
  margin-left: -300px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.16), 0px 2px 10px 0px rgba(0, 0, 0, 0.12);
  display:none;
}
div.modal div{
  padding:3em;
  text-align: center;

}

div.infoContainer {
  padding-top: 40px;
}

.row.detail .col-xs-12{
  padding: 0;
}

@media (max-width: @screen-xs-max) {
  .container-fluid{
    padding:0;
  }
  div#order-resume .dreta{
    text-align: inherit;
  }
  div#order-resume .visible-xs-inline{
    font-weight: bold;
  }
  .article .inside {
    padding: 0;
  }
  a.logo {
    margin: auto;
    margin-top:70px;
  }

  .slider_caption {
    position: static;
    top: inherit;
    left: inherit;
    padding: 30px 50px;
    max-width: 100%;
  }

  .slider.home {
    height: inherit;
  }
  .slider span.title {
    font-size: 25px;
  }
  .slider span.description {
    font-size: 25px;
  }
  .slider.detail-product {
    height: inherit;
  }
  div.modal{
    left: 0;
    top: 30%;
    margin-left: 0;
    height:inherit;
  }
  .btn{
    margin-bottom: 10px;
  }
  .form-fields .row [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .numItems {
    display: inline;
    margin-left:0;
    font-size: 20px;
    color: white;
    font-weight: bold;
  }


  }

div#order-resume .row {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  padding-top: 10px;
}
div#order-resume .row.header {
  font-weight: bold;
}

div#order-resume .row:first-child, div#order-resume .row:last-child {
  border:none;
}
div#mobile-menu-bar {
  background-color: @fontRed;
  padding: 15px 15px;
  position: fixed;
  width: 100%;
  z-index: 2000;
  top:0;
  left:0;
}
div#mobile-menu-bar a{
  color:white;
  font-weight: bold;
  text-decoration: none;
  font-size: @fontMd;
}

div#mobile-menu-bar ul {
  padding: 0;
  list-style: none;
  margin: 15px 0 0;
}
div#mobile-menu-bar ul li {
  padding: 10px 0;
  border-bottom: 2px solid #7B0303;
}
div#mobile-menu-bar ul li:last-child{
  border-bottom: none;
}
.form-control{
  margin-bottom: 10px;
}