@fa-font-path: "font-awesome";

@font-face {
  font-family: 'FontAwesome';
  src: font-url('@{fa-font-path}/@{fa-font-name}.eot?v=@{fa-version}');
  src: font-url('@{fa-font-path}/@{fa-font-name}.eot?#iefix&v=@{fa-version}') format('embedded-opentype'),
    font-url('@{fa-font-path}/@{fa-font-name}.woff?v=@{fa-version}') format('woff'),
    font-url('@{fa-font-path}/@{fa-font-name}.ttf?v=@{fa-version}') format('truetype'),
    font-url('@{fa-font-path}/@{fa-font-name}.svg?v=@{fa-version}#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
